<template>
    <div>
        <div class="top" :style="{
            'background': `#FFFFFF linear-gradient(to bottom,${showStageData.bannerColor
                }, rgba(202, 226, 255, 0))`
        }">
            <div class="topLeft">
                <img class="bannerImg" :src="showStageData.bannerImg" />
                <template v-for="(e, i) in positions" :key="i">
                    <div v-if="e.haveStage" :style="{
                        position: 'absolute',
                        color: e.isMainPosition ? e.stageData.mainColor : '',
                        left: e.x + 'px',
                        top: e.y + 'px',
                        opacity: e.opacity,
                    }" :class="['stageLink', e.isMainPosition && 'stageLinkMain']"
                        @click="stageOnClick(e.stageData.stageValue)">
                        {{ e.stageData.title }}
                        <span @click="goCurStage" class="toCurBtn" v-if="e.isMainPosition
                        && curStage !== showStage">
                            回当前
                        </span>
                    </div>
                </template>
            </div>
            <div class="topRight">
                <div class="topRight0">
                    <div class="topRightLabel">
                        目标分数
                    </div>
                    <div class="topRightValue">
                        {{ targetPoint }}<span class="topRightUnit">分</span>
                    </div>
                </div>
                <div class="topRight1">
                    <div class="topRightLabel"> 离考试还有 </div>
                    <div class="topRightValue">
                        {{ beforeExamNum }}<span class="topRightUnit">天</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="bottomTop">
                <div class="card" :style="{
                    background: showStageData.tableBgColor,
                    cursor: 'pointer'
                }" @click="toTablePage">
                    <img :src="imgTable" class="cardIcon" />
                    <div class="cardTitle" :style="{ color: showStageData.mainColor }">
                        专属课表
                    </div>
                    <div class="cardDes" v-if="weekNeedPractice">
                        当前阶段，本周还需与外教老师练习{{ weekNeedPractice }}节课哦
                    </div>
                </div>
                <div class="card" :style="{ background: showStageData.realQuestionBgColor }" @click="toDetailPage">
                    <img :src="imgRealQuestion" class="cardIcon" />
                    <div class="cardTitle">
                        必练真题
                    </div>
                    <div class="cardDes">
                        当前阶段，每天练习{{ numIntervalStr }}个话题
                    </div>
                </div>
            </div>
            <div class="bottombottom">
                <div class="bbtitle">
                    <img :src="imgTips" class="bbTitleIcon" />
                    <span>进阶tips：</span>
                </div>
                <div class="bbContent" v-html="stageTips">
                </div>
                <div class="bbExtra"><span @click="visible = true">联系小助手</span>
                </div>
            </div>
        </div>
        <StageAisDialog :visible="visible" @close="visible = false" />
    </div>
</template>
<script>
import img1 from '../../../assets/img/oralStage/one@2x.png'
import img2 from '../../../assets/img/oralStage/tow@2x.png'
import img3 from '../../../assets/img/oralStage/three@2x.png'
import img4 from '../../../assets/img/oralStage/four@2x.png'
import imgTable from '../../../assets/img/oralStage/icon_zskb@2x.png'
import imgRealQuestion from '../../../assets/img/oralStage/icon_blzt@2x.png'
import imgTips from '../../../assets/img/oralStage/icon_tips@2x.png'
import http from "@/utils/request";
import { mapState } from 'vuex'
import StageAisDialog from './StageAisDialog/StageAisDialog.vue'


export default {
    name: "stage",
    components: {
        StageAisDialog
    },
    data() {
        return {
            imgTable,
            imgRealQuestion,
            imgTips,
            visible: false,
            weekNeedPractice: 0,
            stageTipsMap: {},
            showStage: 1, //（1:预备期 2:热身期 3:进阶期 4:冲刺期
            curStage: 1, //
            stages: [1, 2, 3, 4], // 用户的所拥有的时期们
            beforeExamNum: '',
            stageDataMap: {
                1: {
                    bannerImg: img1,
                    mainColor: 'rgba(51, 119, 255, 1)',
                    bannerColor: 'rgba(51, 119, 255, 0.2)',
                    tableBgColor: 'rgba(231, 236, 254, 1)',
                    realQuestionBgColor: 'rgba(234, 245, 254, 1)',
                    title: '预备',
                    stageValue: 1,
                },
                2: {
                    bannerImg: img2,
                    mainColor: 'rgba(24, 204, 242, 1)',
                    bannerColor: 'rgba(59, 218, 235, 0.2)',
                    tableBgColor: 'rgba(221, 252, 255, 1)',
                    realQuestionBgColor: 'rgba(238, 255, 253, 1)',

                    title: '热身',
                    stageValue: 2,
                },
                3: {
                    bannerImg: img3,
                    mainColor: 'rgba(245, 150, 67, 1)',
                    bannerColor: 'rgba(255, 228, 174, 0.2)',
                    tableBgColor: 'rgba(255, 246, 240, 1)',
                    realQuestionBgColor: 'rgba(254, 249, 234, 1)',
                    title: '进阶',
                    stageValue: 3,
                },
                4: {
                    bannerImg: img4,
                    mainColor: 'rgba(238, 77, 77, 1)',
                    bannerColor: 'rgba(255, 188, 172, 0.2)',
                    tableBgColor: 'rgba(255, 246, 240, 1)',
                    realQuestionBgColor: 'rgba(254, 249, 234, 1)',
                    title: '冲刺',
                    stageValue: 4,
                },
            }
        }
    },
    watch: {
        showStage: {
            handler(c, p) {
                console.log(c, 'nobody')
                if (c && c !== p) {
                    this.getStageData()
                }
            },
            immediate: true,
        },
        targetPoint: {
            handler(c, p) {
                if (c && c !== p) {
                    this.getStageData()
                }
            },
            immediate: true,
        }
    },
    async mounted() {
        const res = await http(
            `/hcp/yida/studyPlan/getStudyPlanDetailsByPlanId/${this.$route.query.planId}`,
            {
                method: "GET",
            }
        );
        this.$store.commit("oral/UPDATE_STATE_VALUE", {
            key: 'targetScore',
            data: res?.targetScore
        });
        this.beforeExamNum = res?.examRemainDays + ''
        this.curStage = res?.todayPhase
        this.showStage = this.curStage
        this.weekNeedPractice = res.weekDistributeNum - res.weekPracticeNum
        console.log(this.showStage, 'guai')
    },
    computed: {
        ...mapState({
            targetPoint: s => s.oral.targetScore
        }),
        stageTips() {
            return this.stageTipsMap[this.showStage]?.tips;
        },
        numIntervalStr() {
            console.log(this.stageTipsMap[this.showStage], '又没哟')
            return this.stageTipsMap[this.showStage]?.numIntervalStr;
        },
        showStageName() {
            return ({ 1: '预备', 2: '冲刺', 3: '进阶', 4: '冲刺' })[this.showStage]
        },
        showStageData() {
            return this.stageDataMap[this.showStage]
        },
        positions() {
            const positionMap = {
                0: { x: 45, y: 0, opacity: 0.5, },
                1: { x: 57, y: 22, opacity: 0.5, },
                2: { x: 69, y: 44, opacity: 0.5, },
                3: { x: 69, y: 63, opacity: 1, },
                4: { x: 69, y: 96, opacity: 0.5, },
                5: { x: 57, y: 119, opacity: 0.5, },
                6: { x: 45, y: 143, opacity: 0.5, }
            }


            const curStage = this.showStage
            const userStages = this.stages
            const arrIndex = userStages.findIndex(e => e === curStage)
            const begIndex = 3 - arrIndex
            const result = [0, 1, 2, 3, 4, 5, 6].map((e, i) => {
                const sIndex = (i - begIndex)
                const haveStage = i >= begIndex && sIndex < userStages.length
                const stage = userStages[sIndex]
                return {
                    ...positionMap[e],
                    haveStage,
                    isMainPosition: i === 3,
                    stageData: haveStage ? this.stageDataMap[stage] : null
                }
            })

            return result;
        },
    },
    methods: {
        async getStageData() {
            if (this.showStage && this.targetPoint && !this.stageTipsMap[this.showStage]) {
                const res = await http(
                    `/hcp/yida/studyPlan/getTipsByPhaseAndTargetScore`,
                    {
                        method: "GET",
                        params: {
                            phase: this.showStage,
                            targetScore: this.targetPoint
                        }
                    }
                );
                console.log(res, '这个res')
                this.stageTipsMap = {
                    ...this.stageTipsMap,
                    [this.showStage]: res
                }
            }
        },
        goCurStage() {
            this.showStage = this.curStage
        },
        stageOnClick(stage) {
            this.showStage = stage
        },
        toTablePage() {
            this.$router.push({
                path: "/oral",
                query: { type: "plan", planId: this.$route.query.planId },
            });
        },
        toDetailPage() {
            this.$router.push({
                path: "/oral",
                query: {
                    type: "detail",
                    id: this.$route.query.planId,
                    planIdMode: 'true',
                },
            });
        }
    },
    props: {
    }
};
</script>
<style lang="scss" scoped>
.toCurBtn {
    display: inline-block;
    height: 18px;
    background: #EEF6FF;
    border-radius: 5px;
    border: 1px solid #0096FF;
    padding: 0 4px;

    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0096FF;
    line-height: 18px;


    vertical-align: middle;
    position: relative;
    top: -2px;
}

.bottombottom {
    margin-top: 48px;
}

.bbTitleIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.bbContent {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #83888C;
    line-height: 20px;
    margin-top: 16px;
    white-space: pre-wrap;
}

.bbExtra {
    margin-top: 12px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #83888C;

    span {
        color: rgba(51, 119, 255, 1);
        text-decoration: underline;
        cursor: pointer;
    }
}

.bbtitle {


    display: flex;
    align-items: center;

    font-size: 16px;
    font-family: SourceHanSansCN-Bold,
        SourceHanSansCN;
    font-weight: bold;
    color: #333643;
}

.bottom {
    padding: 19px;
    padding-top: 16px;


    background: #FFFFFF;
    border-radius: 6px;
    min-height: 355px;
}

.bottomTop {
    display: flex;
    justify-content: space-between;
}

.card {
    position: relative;
    width: 448px;
    border-radius: 16px;
    padding: 16px;

    .cardIcon {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
    }

    .cardTitle {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 12px;
    }

    .cardDes {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8D95A4;
        white-space: nowrap;
    }
}


.top {
    height: 167px;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 51px;
    padding-right: 80px;

    margin-bottom: 14px;
}


.topLeft {
    position: relative;
    height: 100%;
    user-select: none;
}

.bannerImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 62px;
    height: 164px;
}

.stageLink {
    white-space: nowrap;
    height: 18px;

    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #B5B6BA;
    line-height: 18px;
    border-bottom: 1px solid #B5B6BA;
    cursor: pointer;

}

.stageLinkMain {
    font-size: 24px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    border: none;

}


.topRightLabel {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8D95A4;
    line-height: 20px;
    margin-bottom: 8px;
}

.topRightValue {
    font-size: 24px;
    font-family: Barlow-Bold, Barlow;
    font-weight: bold;
    color: #333643;
    line-height: 29px;
}

.topRightUnit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8D95A4;
    line-height: 20px;
    margin-left: 8px;
}

.topRight {
    display: flex;
}

.topRight0 {
    margin-right: 47px;
}
</style>