<template>
  <div class="user_oral_records_page_container">
    <div v-if="$route.query.type === 'stage'" class="btns">
      <el-button style="width: 129px" @click="historyBack" type="primary">返回</el-button>
      <el-button style="width: 129px" @click="handleBookCourse()" type="primary">去约课
      </el-button>
    </div>
    <div v-else-if="$route.query.type === 'plan'" class="btns">
      <!-- <el-button v-if="$route.query.type === 'plan'" style="width: 129px"
        @click="$route.query.history ? $router.back() : $router.push('oral')" type="primary">返回</el-button> -->
      <el-button v-if="$route.query.type === 'plan'" style="width: 129px" @click="$router.back()"
        type="primary">返回</el-button>
      <el-button v-if="!$route.query.history" @click="historyPlan" :type="historyShow ? 'info' : 'primary'"
        :disabled="historyShow">历史计划</el-button>
      <template v-if="!$route.query.history">
        <el-button @click="handleSetPlan()" type="primary">修改计划</el-button>
      </template>
    </div>
    <el-button v-else-if="$route.query.type === 'history' || $route.query.type === 'detail'
      " style="width: 129px; font-weight: 600" @click="historyBack" type="primary" class="btns">返回</el-button>
    <div v-else>
      <my-tabs :tabs="tabs" v-model:activeTab="activeTab" class="tabs"></my-tabs>
      <div v-show="btnTitle" class="set_btn" @click="handleSetPlan()">
        {{ btnTitle }}
      </div>
      <div class="book_btn" @click="handleBookCourse()">
        约课({{ oralCount }})
      </div>
    </div>
    <div class="oral_table_container">
      <!-- 口语计划 -->
      <div v-if="$route.query.type === 'stage'">
        <stage />
      </div>
      <div v-else-if="$route.query.type === 'plan'">
        <calendar :type="planType" :toBookCourse="toBookCourse" />
      </div>
      <div v-else-if="$route.query.type === 'history'">
        <history :data="historyData" @changePage="historyChange" :total="historyTotal" :type="planType" />
      </div>
      <div v-else-if="$route.query.type === 'detail'">
        <practice type="oral" :data="oralDetail" :num="oralCount" :handleBookCourse="handleBookCourse" />
      </div>
      <!-- 没有剩余课时 -->
      <div class="not_fount_page" v-else-if="oralRecords.total <= 0">
        <img class="not_found_img" src="@/assets/img/img_404@3x.png" />
        <div>
          <div class="book_course_text">你还没有约课哦～</div>
          <el-button class="book_course_btn" type="primary" @click="handleBookCourse()">去约课</el-button>
        </div>
      </div>
      <!-- 已绑定老师 -->
      <div class="not_fount_page" v-else-if="!hasBindTeacher && oralCount > 0">
        <img class="not_found_img" src="@/assets/img/img_404@3x.png" />
        <div>
          <div class="book_course_text">
            还没有绑定老师哦~联系小助手绑定老师后才可以约课
          </div>
          <el-button class="book_course_btn" type="primary" @click="toBindTeacher">去绑定老师</el-button>
        </div>
      </div>
      <div>
        <OralLessonList :statusList="statusList" :commenNow="commenNow" :cancelBook="cancelBook" :activeTab="activeTab"
          ref="OralLessonList" />
        <!-- <my-table :empty-text="wrapEmptyText()" :columns="columns" :is-page="true" :table-data="oralRecords.list || []"
          :total="oralRecords.total || 0" :handle-arr="handleActions" @changePage="changePage">
          <add-assistant v-if="showAssistant()" :accounts-info="oralAssistant"></add-assistant>
        </my-table> -->

        <cancel-modal v-model:dialogVisible="cancelVisible" :is-urgent="activeRowUrgent"
          @confirm="confirmCancel"></cancel-modal>
        <evaluate-modal ref="evaluate_modal" v-model:dialogVisible="evaluateVisible"
          @confirm="confirmEvaluate"></evaluate-modal>
      </div>
    </div>
    <plan-dialog :visible="dialogFormVisible" @planDialog="planDialog" :type="planType"
      :planData="formData"></plan-dialog>
    <bind-teacher-dialog @bindClose="bindClose" :visible="bindShow"></bind-teacher-dialog>
    <StageAisDialog :visible="showQRcode" @close="assisClose"></StageAisDialog>
    <!-- <assistant-dialog :visible="showQRcode" @close="assisClose"></assistant-dialog> -->

    <followed-pub-account ref="pubAccount" @nextHandler="nextHandler" type="oral"></followed-pub-account>
  </div>
</template>

<script>
import { transferCNTimeToLocal, showBjTime, isCN } from '@/utils/timezone';
import planDialog from '@/components/planDialog.vue';
import Calendar from '@/components/PlanCalendar.vue';
import http from '@/utils/request';
import History from '@/components/History.vue';
import Practice from '@/components/Practice.vue';
import FollowedPubAccount from '@/components/FollowedPubAccount/index.vue';
import StageAisDialog from '@/views/oral/components/StageAisDialog/StageAisDialog.vue';
import MyTabs from '../../components/MyTabs.vue';
import MyTable from '../../components/MyTable.vue';
import { connectState } from '../../utils/wyUtil';
import { recordStatus, recordStatusStyle } from './config';
import CancelModal from './components/CancelModal.vue';
import EvaluateModal from './components/EvaluateModal.vue';
import AddAssistant from './components/AddAssistant.vue';
import Stage from './components/Stage.vue';
import BindTeacherDialog from './components/BindTeacherDialog.vue';
import AssistantDialog from './components/AssistantDialog.vue';
import OralLessonList from './components/OralLessonList';

export default {
  name: 'MyCourse',
  inject: ['reload'],
  components: {
    OralLessonList,
    Stage,
    StageAisDialog,
    AddAssistant,
    EvaluateModal,
    CancelModal,
    MyTable,
    MyTabs,
    planDialog,
    Calendar,
    History,
    Practice,
    BindTeacherDialog,
    AssistantDialog,
    FollowedPubAccount,
  },
  data() {
    return {
      curPage: 1,
      limit: 10,
      tabs: [
        // 状态 0待上课 1完成 2学生普通取消 3老师普通取消 4学生紧急取消 5老师紧急取消 6学生缺席 7老师缺席
        { label: '待上课', value: 0, status: 1 },
        { label: '已结束', value: 1, status: 2 },
        { label: '已取消', value: 2, status: 3 },
      ],
      activeTab: 0,
      activeRow: {},
      activeRowUrgent: false,
      columns: [
        {
          prop: 'courseCode',
          label: '约课编号',
          showToolTip: true,
          width: 90,
        },
        { prop: 'teacherName', label: '教师名称', width: 90 },
        { prop: 'studentName', label: '学生姓名', width: 90 },
        {
          prop: 'beginTime',
          label: '上课时间',
          width: 220,
          showToolTip: true,
          renderDom: (row) => {
            const begin = transferCNTimeToLocal(
              row.beginTime,
              'YYYY-MM-DD HH:mm',
            );
            const end = transferCNTimeToLocal(row.endTime, 'HH:mm');
            return `<div>
              <p style="font-size:15px;">${begin}-${end}</p>
              <p style="font-size:12px;color:#a0a2ab;display:${this.isCN() ? 'none' : 'block'
}">(北京时间 ${showBjTime(
  row.beginTime,
  'YYYY-MM-DD HH:mm',
)}-${showBjTime(row.endTime, 'HH:mm')})</p>
              </div>`;
          },
        },
        { prop: 'qq', label: 'QQ号' },
        {
          prop: 'courseTime',
          label: '约课时间',
          showToolTip: true,
          width: 90,
        },
        {
          prop: 'status',
          label: '预约状态',
          width: 90,
          style: (row) => recordStatusStyle[row.status],
          render: (row) => recordStatus[row.status],
        },
      ],
      handleActions: [
        {
          text: '取消预约',
          visible: (row) => row.status === 0,
          disabled: (row) => this.checkDisable(row.beginTime),
          action: this.cancelBook,
        },
        {
          text: '立即评价',
          visible: (row) => row.status === 1,
          action: this.commentNow,
        },
      ],
      cancelVisible: false,
      evaluateVisible: false,
      accountsInfo: [],
      dialogFormVisible: false,
      planType: 1,
      btnTitle: '',
      formData: {}, // 修改计划时，表单的值
      historyShow: false,
      historyTotal: 0,
      historyData: [], // 历史计划数组
      bindShow: false,
      showQRcode: false,
      bookCourseFromOralId: undefined,
    };
  },
  computed: {
    ...connectState('oral', [
      'oralRecords',
      'oralCount',
      'hasBindTeacher',
      'bindTeachers',
      'oralAssistant',
      'oralDetail',
    ]),
    ...connectState('login', ['userInfo']),
    statusList() {
      return this.tabs[this.activeTab].status;
    },
  },
  watch: {
    $route(to, from) {
      this.reload();
    },
    activeTab(val) {
      this.curPage = 1;
      const statusList = (
        this.tabs.find((item) => item.value === val * 1) || {}
      ).status || [0];
      // this.getOralRecords(statusList);
    },
    assistants(val) {
      this.accountsInfo = Array.isArray(val)
        ? val.map((item) => ({ name: item.key || '', url: item.value }))
        : [];
    },
    oralCount(val) {
      if (val <= 0 && this.bindTeachers) {
        this.accountsInfo = Array.isArray(this.bindTeachers)
          ? this.bindTeachers.map((item) => ({
            name: item.teacherName || '',
            url: item.teacherRealWx,
          }))
          : [];
      }
    },
  },
  created() {
    const statusList = this.tabs[0].status;
    this.getBindTeachers();
    // this.getOralRecords(statusList);
    this.getOralCount();
    this.getOralPlan();
    this.getOralDetail();
  },
  methods: {
    showBjTime,
    isCN,
    cancelBook(row) {
      this.activeRow = row;
      this.activeRowUrgent = this.checkIsUrgent(row.beginTime);
      this.cancelVisible = true;
    },
    commenNow(row) {
      this.activeRow = row;
      this.evaluateVisible = true;
    },
    assisClose() {
      this.showQRcode = false;
    },
    toTablePage() {
      this.$router.push({
        path: '/oral',
        query: { type: 'plan', planId: this.$route.query.planId },
      });
    },
    transferCNTimeToLocal,
    changePage(page) {
      this.curPage = page;
      const statusList = this.tabs[this.activeTab].status;
      // this.getOralRecords(statusList);
    },
    // 口语计划详情
    async getOralDetail() {
      if (this.$route.query.type === 'detail') {
        this.$store.dispatch('oral/getDetail', {
          id: this.$route.query.id,
          planIdMode: !!this.$route.query.planIdMode,
        });
      }
    },
    wrapEmptyText() {
      if (!this.hasBindTeacher) {
        return '绑定老师后才能约课哦，快添加小助手微信吧！';
      }
      return '当前没有课程哦！';
    },
    showAssistant() {
      return !this.hasBindTeacher;
    },
    getBindTeachers() {
      this.$store.dispatch('oral/getBindTeachers');
    },
    getOralRecords(statusList) {
      this.$store.dispatch('oral/userOralRecords', {
        statusList: statusList.join('_'),
        curPage: this.curPage,
        limit: this.limit,
      });
    },
    getOralCount() {
      this.$store.dispatch('oral/fetchOralCount');
    },
    toBindTeacher() {
      const { nameEn, qqNumber, wxNumber } = this.userInfo;
      if (nameEn && qqNumber && wxNumber) {
        this.showQRcode = true;
      } else {
        this.bindShow = true;
      }
    },
    bindClose() {
      this.bindShow = false;
    },
    // toBook() {
    //   this.$router.push({ name: "home", params: { tabs: 3 } });
    // },
    async toBookCourse() {
      if (!this.hasBindTeacher) {
        this.$router.push('/oral');
        return false;
      }
      if (this.oralCount <= 0) {
        this.$router.push('/oral');
        return false;
      }
      this.$router.push('/bookCourse');
      return true;
    },
    async handleBookCourse(oralTopicId) {
      this.bookCourseFromOralId = oralTopicId;

      if (this.oralCount <= 0) {
        if (oralTopicId) {
          this.$router.push({ path: '/oral' });
        } else {
          this.$message({
            type: 'warning',
            message: '你的可用约课次数已不足，请先联系小助手购买服务',
          });
          // this.$router.push({ name: 'home', params: { tabs: 3 } });
        }
        return false;
      }
      if (!this.hasBindTeacher) {
        this.$message({
          type: 'warning',
          message: '您还未绑定老师，请加小助手微信',
        });
        return false;
      }
      const b = window.localStorage.getItem('isOpen');
      const c = JSON.parse(b); // 将 JSON 字符串转成对象
      const type = this.$route.query.type ? this.$route.query.type : 'oral';
      const canShow = await this.$store.dispatch('oral/canShowPubAccount');
      const isOralFirstOpen = JSON.parse(
        window.localStorage.getItem('isOralFirstOpen'),
      );
      const isOralStageFirstOpen = JSON.parse(
        window.localStorage.getItem('isOralStageFirstOpen'),
      );
      const isOralPlanOpen = JSON.parse(
        window.localStorage.getItem('isOralPlanOpen'),
      );
      if (canShow) {
        if (c) {
          const isOpen = c.isOpen === '0';
          const { time } = c;
          const { date } = c;
          if (parseInt(time) + parseInt(date) < new Date().getTime() / 1000) {
            // 如果 date + time 小于当前时间的时间戳，说明已经超过24小时的时效时间
            window.localStorage.removeItem('isOralFirstOpen');
            window.localStorage.removeItem('isOralStageFirstOpen');
            window.localStorage.removeItem('isOralPlanOpen');
            this.$refs.pubAccount.handleOpen(type);
            return false;
          }
          if (type === 'oral') {
            if (!isOralFirstOpen) {
              this.$refs.pubAccount.handleOpen(type);
              return false;
            }
          } else if (type === 'stage') {
            if (!isOralStageFirstOpen) {
              this.$refs.pubAccount.handleOpen(type);
              return false;
            }
          } else if (!isOralPlanOpen) {
            this.$refs.pubAccount.handleOpen('oralPlan');
            return false;
          }
          if (c && isOpen) {
            // Do something with return value
            this.nextHandler();
            return true;
          }
        } else {
          window.localStorage.removeItem('isOralFirstOpen');
          window.localStorage.removeItem('isOralStageFirstOpen');
          window.localStorage.removeItem('isOralPlanOpen');
          window.localStorage.removeItem('isSubmitFirstOpen');
          window.localStorage.removeItem('isPracticeFirstOpen');
          this.$refs.pubAccount.handleOpen(type);
          return false;
        }
      } else {
        this.nextHandler();
        return true;
      }
    },
    toBookCoursePage(oralTopicId) {
      if (oralTopicId) {
        http('/hcp/yida/studyPlan/oralTopicPractited', {
          method: 'GET',
          params: {
            oralTopicId,
          },
        });
        this.$router.push(
          `/bookCourse?bookCourseFromOralId=${this.bookCourseFromOralId}`,
        );
      } else {
        this.$router.push('/bookCourse');
      }
    },
    nextHandler() {
      if (this.bookCourseFromOralId) {
        this.$router.push(
          `/bookCourse?bookCourseFromOralId=${this.bookCourseFromOralId}`,
        );
      } else {
        this.$router.push('/bookCourse');
      }
    },
    confirmCancel() {
      const isUrgentNow = this.checkIsUrgent(this.activeRow);
      if (!this.isUrgent && isUrgentNow) {
        this.$message.error('取消失败，当前时间只能紧急取消课程!');
        this.cancelVisible = false;
        this.activeRowUrgent = false;
        this.activeRow = {};
        return false;
      }

      this.$store.dispatch('oral/cancelBookedCourse', {
        courseLogId: this.activeRow.courseLogId,
        callback: () => {
          this.$message({
            message: this.isUrgent ? '紧急取消成功!' : '取消成功!',
            type: 'success',
          });
          this.cancelVisible = false;
          this.activeRowUrgent = false;
          this.activeRow = {};
          const statusList = this.tabs[this.activeTab].status;
          this.$refs.OralLessonList.cancelCallBack
            && this.$refs.OralLessonList.cancelCallBack();
          this.getOralCount();
        },
      });

      return true;
    },
    checkIsUrgent(beginTime) {
      const now = new Date().getTime();
      const begin = new Date(beginTime).getTime();
      return begin - now <= 3 * 60 * 60 * 1000;
    },
    checkDisable(beginTime) {
      const now = new Date().getTime();
      const begin = new Date(beginTime).getTime();
      return begin < now;
    },
    confirmEvaluate() {
      const score = this.$refs.evaluate_modal.rate;
      const content = this.$refs.evaluate_modal.evaContent;
      this.$store.dispatch('oral/addCourseComment', {
        commentType: 17,
        score,
        content,
        subjectId: this.activeRow.teacherId,
        callback: () => {
          this.$message({ message: '评价成功!', type: 'success' });
          this.activeRow = {};
          this.evaluateVisible = false;
        },
      });
      return true;
    },
    async handleSetPlan() {
      if (this.btnTitle == '查看计划') {
        // this.$router.push({
        //   path: "/oral",
        //   query: { type: "plan", planId: this.planId },
        // });

        this.$router.push({
          path: '/oral',
          query: { type: 'stage', planId: this.planId },
        });
      }
      if (this.btnTitle == '修改计划') {
        if (this.$route.query.planId) {
          const res2 = await http(
            `/hcp/yida/studyPlan/getStudyPlanInfo/${this.$route.query.planId}`,
            {
              method: 'GET',
            },
          );
          this.formData = res2;
          this.dialogFormVisible = true;
        }
      }
      if (this.btnTitle == '设置计划') {
        this.dialogFormVisible = true;
      }
    },
    async getHistoryList() {
      const limit = 10;
      const res = await http(
        `/hcp/yida/studyPlan/getUserHistoryStudyPlanPage?type=1&curPage=${this.curPage}&limit=${limit}`,
        {
          method: 'GET',
        },
      );
      if (res.total !== 0) {
        this.historyData = res.list;
        this.historyTotal = res.total;
      } else {
        this.historyShow = true;
      }
    },
    planDialog(data) {
      this.dialogFormVisible = data;
    },
    async getOralPlan() {
      const res = await http('/hcp/base/base/dict/studyPlanSwitch/oral', {
        method: 'GET',
      });
      if (Number(res)) {
        const res1 = await http('/hcp/yida/studyPlan/getUserLastPlanId/1', {
          method: 'GET',
        });
        if (res1) {
          this.btnTitle = '查看计划';
          if (this.$route.query.type == 'plan') {
            this.btnTitle = '修改计划';
          }
          this.planId = res1;
        } else {
          this.btnTitle = '设置计划';
        }
        // 是否展示历史计划按钮
        this.getHistoryList();
      }
    },
    historyChange(page) {
      this.curPage = page;
      this.getHistoryList();
    },
    // 历史计划
    async historyPlan() {
      this.$router.push({
        path: '/oral',
        query: { type: 'history' },
      });
    },
    historyBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.user_oral_records_page_container {
  width: 100%;
  position: relative;

  .tabs {
    margin-bottom: 15px;
  }

  .btns {
    margin-bottom: 20px;

    .el-button {
      font-weight: 600 !important;
    }
  }

  .book_btn {
    width: 129px;
    height: 38px;
    background: #4e596f;
    border-radius: 6px;
    text-align: center;
    position: absolute;
    right: 20px;
    top: 0;
    cursor: pointer;

    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    line-height: 38px;

    &:hover {
      opacity: 0.6;
    }
  }

  .set_btn {
    width: 129px;
    height: 38px;
    background: #4e596f;
    border-radius: 6px;
    text-align: center;
    position: absolute;
    right: 159px;
    top: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    line-height: 38px;

    &:hover {
      opacity: 0.6;
    }
  }

  .oral_table_container {
    width: 950px;
    border-radius: 6px;
    overflow: hidden;

    .not_fount_page {
      text-align: center;
      margin-top: 180px;

      .not_found_img {
        display: inline-block;
        width: 145px;
        height: 127px;
      }

      .book_course_text {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #8e95a3;
        line-height: 24px;
        margin-top: 16px;
      }

      .book_course_btn {
        width: 129px;
        height: 38px;
        background: #3377ff;
        border-radius: 6px;
        margin-top: 40px;
      }
    }
  }
}
</style>
