<template>
  <div class="evaluate_modal_wrapper">
    <el-dialog
      title="上课评价"
      v-model="visible"
      :center="true"
      width="30%">
     <p class="rate_row">
       <span>评分：</span>
       <el-rate
         v-model="rate"
         :colors="colors">
       </el-rate>
     </p>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请填写评价"
        v-model="evaContent">
      </el-input>

      <template #footer>
      <span class="dialog-footer">
        <el-button class="footer_btn"
                   type="primary"
                   plain
                   @click="$emit('update:dialogVisible',false)">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>

export default {
  name: 'evaluateModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rate: '',
      evaContent: '',
      colors: ['#F7BA2A', '#FFAA1E', '#FF9900'],
    };
  },
  watch: {
    dialogVisible(val) {
      this.visible = val;
    },
  },
  methods: {
    handleConfirm() {
      if (!this.rate) {
        this.$message.error('请勾选评分');
        return false;
      }
      if (!this.evaContent) {
        this.$message.error('请填写评价');
        return false;
      }
      this.$emit('confirm');
      return true;
    },
  },
};

</script>
<style lang="scss" scoped>
  .evaluate_modal_wrapper{

    .rate_row{
      display:flex;
      flex-direction: row;
      margin-bottom:20px;
    }

  }

</style>
