<template>
  <div class="add_assistant_page_container">
    <p class="title">添加小助手微信</p>
    <div class="images_container">
      <img :src="accountsInfo" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "addAssistant",
  props: {
    accountsInfo: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="scss" scoped>
.add_assistant_page_container {
  margin-top: 30px;
  .title {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #333b50;
    line-height: 25px;
  }
  .images_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .img_item {
      margin-top: 15px;
    }
    img {
      width: 193px;
      height: 193px;
      border-radius: 3px;
      overflow: hidden;
      max-width: 100%;
    }
  }
}
</style>
