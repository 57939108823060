/* eslint-disable */
export const recordStatus = {
  //  状态 0待上课 1完成 2学生普通取消 3老师普通取消 4学生紧急取消 5老师紧急取消 6学生缺席 7老师缺席 ,
  0: "待上课",
  1: "完成",
  2: "学生普通取消",
  3: "老师普通取消",
  4: "学生紧急取消",
  5: "老师紧急取消",
  6: "学生缺席",
  7: "老师缺席"
};

export const statusStyleNew = {
  0: {
    backgroundColor: "E6A23C",
    color: "white"
  },
  1: {
    backgroundColor: "rgba(47, 200, 60, 1)",
    color: "white"
  },
  2: {
    backgroundColor: "rgba(238, 106, 91, 1)",
    color: "white"
  },
  3: {
    backgroundColor: "rgba(238, 106, 91, 1)",
    color: "white"
  },
  4: {
    backgroundColor: "rgba(238, 106, 91, 1)",
    color: "white"
  },
  5: {
    backgroundColor: "rgba(238, 106, 91, 1)",
    color: "white"
  },
  6: {
    backgroundColor: "rgba(238, 106, 91, 1)",
    color: "white"
  },
  7: {
    backgroundColor: "rgba(238, 106, 91, 1)",
    color: "white"
  }
};
export const recordStatusStyle = {
  //  状态 0待上课 1完成 2学生普通取消 3老师普通取消 4学生紧急取消 5老师紧急取消 6学生缺席 7老师缺席 ,
  0: "color:#E6A23C;",
  1: "color:#67C23A;",
  2: "color:#F56C6C;",
  3: "color:#F56C6C;",
  4: "color:#F56C6C;",
  5: "color:#F56C6C;",
  6: "color:#909399;",
  7: "color:#909399;"
};

//预约状态 0：未预约，1：已预约，2：删除 ,
export const courseStatus = {
  0: "未预约",
  1: "已预约",
  2: "删除"
};
