
<template>
    <div class="assistant_container">
        <el-dialog center title="添加小助手微信" v-model="dialogFormVisible" :before-close="handleClose">
            <div class="tips">使用微信扫码添加</div>
            <div class="images_container">
                <img :src="oralAssistant" alt="" />
            </div>
            <div class="weChatNumber">
                {{ weChatNumber }}
            </div>
            <img src="@/assets/img/oralStage/close@2x.png" class="closeIcon" @click="handleClose" />
        </el-dialog>
    </div>
</template>
  
<script>
import http from "@/utils/request";
export default {
    name: "StageAisDialog",
    props: {
        visible: Boolean,
        oralAssistant: String,
        weChatNumber: String
    },
    mounted() {
        (async () => {
            const res = await http(
                'hcp/yida/course/getOralSalespersonQRCodeV2',
                {
                    method: "GET",
                }
            );
            this.oralAssistant = res?.url || ''
            this.weChatNumber = res?.wxNum || ''
        })()

    },
    data() {
        return {
            dialogFormVisible: false,
            oralAssistant: '',
            weChatNumber: '',
        };
    },
    watch: {
        visible(val) {
            this.dialogFormVisible = val;
        },
    },
    methods: {
        handleClose() {
            this.$emit("close");
            this.dialogFormVisible = false;
        },
    },
};
</script>
<style lang="scss" >
.assistant_container {
    .el-dialog__body {
        padding-top: 2px;
        padding-bottom: 40px;
    }

    .el-dialog__headerbtn {
        display: none;
    }

    .el-dialog {
        background: #FFFFFF;
        border-radius: 24px;
    }

    .el-dialog__header {
        padding-top: 40px;

        .el-dialog__title {

            font-size: 20px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333643;

        }


    }
}
</style>
<style lang="scss" scoped>
.weChatNumber {
    text-align: center;
    margin-top: 16px;

    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333643;
    line-height: 24px;
}

.assistant_container {
    position: relative;

    .closeIcon {
        width: 20px;
        width: 20px;
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }

    .tips {
        font-size: 14px;
        text-align: center;

        font-family: MicrosoftYaHei;
        color: #8D95A4;
    }

    .images_container {
        text-align: center;

        img {
            margin-top: 40px;
            width: 226px;
            height: 226px;
            border-radius: 14px;
        }
    }
}
</style>
  