<template>
  <div class="cancel_book_modal_wrapper">
    <el-dialog
      title="操作提示"
      v-model="visible"
      :center="true"
      width="30%">
     <p class="text_row">
       <span class="normal_text">确定要取消约课吗？</span>
       <span class="warning_text" :class="isUrgent ? 'urgent' : null">
        {{ isUrgent ? "取消后将不返还1课时余额!" : "取消后可以返还1课时余额。" }}
      </span>
     </p>
      <input
        ref="confirm_input"
        type="text"
        placeholder="请输入“确认取消”"
        focus
        class="confirm_input"
        :onInput="(e)=>handleInput(e)"
      />
      <p class="confirm_tip" v-if="showTip">*请输入“确认取消”</p>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="footer_btn"
                   type="primary"
                   plain
                   @click="$emit('update:dialogVisible',false)">取消</el-button>
        <el-button class="footer_btn" type="primary" @click="handleConfirm">确认</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'cancelModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    isUrgent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmStr: '',
      showTip: false,
      visible: false,
    };
  },
  watch: {
    dialogVisible(val) {
      this.visible = val;
      if (!val) {
        this.confirmStr = '';
        this.$refs.confirm_input.value = '';
      }
    },
  },
  methods: {
    handleInput(event) {
      const val = event.target.value.trim();
      this.confirmStr = val;
      this.showTip = this.checkInput(val);
      return val;
    },
    checkInput(val) {
      const reg = /^确认取消$/gi;
      return !reg.test(val);
    },
    handleConfirm() {
      if (this.checkInput(this.confirmStr)) {
        return false;
      }
      this.$emit('confirm');
      return true;
    },
  },

};
</script>
<style lang="scss" scoped>
  .cancel_book_modal_wrapper{
    .text_row{
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 30px;
      /*text-align: center;*/
    }
    .normal_text{
      color:#6D7278;
    }
    .warning_text{
      color:#3377FF;
    }
    .urgent{
      color:#FF3366;
    }
    .confirm_input{
      width:100%;
      height:40px;
      line-height: 400px;
      border: 1px solid #eee;
      border-radius: 5px;
      padding:0 15px;
      outline: none;
    }
    .confirm_tip{
      font-size: 14px;
      color:#FF3366;
      margin-top:10px;
    }
  }

</style>
