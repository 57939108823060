<template>
  <div class="assistant_container">
    <el-dialog center title="添加小助手微信" v-model="dialogFormVisible" :before-close="handleClose">
      <div class="tips">使用微信扫码添加</div>
      <div class="images_container">
        <img :src="oralAssistant" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { connectState } from "@/utils/wyUtil";

export default {
  name: "assistantDialog",
  props: {
    visible: Boolean,
  },
  computed: {
    ...connectState("oral", ["oralAssistant"]),
  },
  data() {
    return {
      dialogFormVisible: false,
    };
  },
  watch: {
    visible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.assistant_container {


  .tips {
    font-size: 14px;
    color: #8d95a4;
    line-height: 24px;
    text-align: center;
  }

  .images_container {
    text-align: center;

    img {
      width: 226px;
      height: 226px;
    }
  }
}
</style>
