<template>
  <div class="bind_container">
    <el-dialog
      center
      title="完善上课信息"
      v-model="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="tips">信息真实有效，方能保证约课顺利哦～</div>
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item
          label="英文名"
          :label-width="formLabelWidth"
          prop="nameEn"
        >
          <el-input v-model="form.nameEn" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="QQ号"
          :label-width="formLabelWidth"
          prop="qqNumber"
        >
          <el-input v-model="form.qqNumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="微信号"
          :label-width="formLabelWidth"
          prop="wxNumber"
        >
          <el-input v-model="form.wxNumber" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template v-slot:footer class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >下一步</el-button
        >
      </template>
    </el-dialog>
    <assistant-dialog
      :visible="showQRcode"
      @close="assisClose"
    ></assistant-dialog>
  </div>
</template>

<script>
import { connectState } from "@/utils/wyUtil";
import http from "@/utils/request";
import AssistantDialog from "./AssistantDialog.vue";

export default {
  name: "bindTeacherDialog",
  components: {
    AssistantDialog,
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...connectState("login", ["userInfo"]),
  },
  created() {
    this.$store.dispatch("login/fetchUserInfo");
  },
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "120px",
      form: {
        nameEn: "",
        qqNumber: "",
        wxNumber: "",
      },
      rules: {
        nameEn: [
          { required: true, message: "请输入英文名", trigger: "blur" },
          { min: 1, max: 20, message: "请输入1-20位的英文名", trigger: "blur" },
        ],
        qqNumber: [
          { required: true, message: "请输入QQ号", trigger: "blur" },
          { min: 5, max: 12, message: "请输入5-12位QQ号", trigger: "blur" },
        ],
        wxNumber: [
          { required: true, message: "请输入微信号", trigger: "blur" },
          { min: 6, max: 20, message: "请输入6-20位的微信号", trigger: "blur" },
        ],
      },
      showQRcode: false,
    };
  },
  watch: {
    visible(val) {
      this.dialogFormVisible = val;
    },
    userInfo(val) {
      if (val) {
        this.form.nameEn = val.nameEn;
        this.form.qqNumber = val.qqNumber;
        this.form.wxNumber = val.wxNumber;
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("bindClose");
      this.dialogFormVisible = false;
    },
    assisClose() {
      this.showQRcode = false;
    },
    submitForm(formName) {
      
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = { ...this.form };
          const res = await http("/hcp/user/info/updateSimpleUserInfo", {
            method: "POST",
            data,
          });
          if (res) {
            this.$store.dispatch("login/fetchUserInfo");
            this.handleClose();
            this.showQRcode = true;
          } else {
            this.$message.error(res.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bind_container {
  .tips {
    text-align: center;
    margin-bottom: 40px;
  }
}
</style>
